<template>
  <div
    v-if="
      !(Object.keys(content).length === 0 && content.constructor === Object)
    "
  >
    <AppHeader />
    <v-container id="post_container" fluid>
      <v-row justify="center">
        <v-col cols="12" sm="10" style="max-width: 1104px">
          <v-row justify="center" style="padding-bottom: 100px">
            <v-col cols="auto">
              <Images
                style="max-width: 1080px"
                width="100%"
                :name="content.headerImage.url"
                :alt="content.headerImage.label"
              />
            </v-col>
            <v-col cols="12" class="py-o px-3 px-sm-0">
              <v-row justify="center" justify-sm="start">
                <v-col cols="auto" sm="1" class="d-none d-sm-block pl-6">
                  <Images
                    @click.native="shareFB"
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/fb_blog_icon_kfaln7.svg"
                    style="margin-top: 60px; margin-bottom: 16px"
                    class="pointer d-block d-sm-flex blogpost-fb-icon"
                  />
                  <Images
                    @click.native="shareTwitter"
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/twitter_blog_icon_gl0qgc.svg"
                    style="margin-bottom: 16px"
                    class="pointer d-block d-sm-flex mb-0 mb-sm-4 mx-4 mx-sm-0"
                  />
                  <Images
                    @click.native="shareIn"
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/linkedin_blog_icon_srnr7c.svg"
                    class="pointer d-block d-sm-flex"
                  />
                </v-col>
                <v-col cols="12" sm="10">
                  <v-row align="center">
                    <v-col
                      cols="auto"
                      sm="auto"
                      class="blog-post-header"
                      style="padding-top: 60px; padding-bottom: 19px"
                    >
                      <Heading type="h2-4 MainDark" class="blog-header">{{
                        content.header
                      }}</Heading>
                    </v-col>
                    <div style="width: 100%"></div>
                    <v-col cols="ayto" style="padding-top: 0">
                      <Texting
                        type="p3small-2 blackColor"
                        v-html="content.description"
                      ></Texting>
                    </v-col>
                    <div style="width: 100%"></div>
                    <v-col cols="12">
                      <div
                        @click="onListItemClick"
                        id="blog-post-list"
                        class="p3small-2"
                        v-html="content.html"
                      ></div>
                    </v-col>
                  </v-row>
                  <v-row
                    align="start"
                    :ref="'postTitle-' + postTitle.contentID + '-' + i"
                    :id="'postTitle-' + postTitle.contentID + '-' + i"
                    v-for="(postTitle, i) in subTitles"
                    :key="i"
                  >
                    <v-col cols="auto" class="" style="padding-top: 36px">
                      <Heading type="MainDark h2-blog">{{
                        postTitle.fields.header
                      }}</Heading>
                    </v-col>
                    <v-row
                      v-for="(n, i) in 11"
                      :key="n"
                      class="px-4"
                      justify="center"
                      justify-sm="start"
                    >
                      <div style="width: 100%"></div>
                      <v-col
                        v-if="postTitle.fields['descriptionheader' + n]"
                        cols="auto"
                        class="pt-7 pb-0"
                      >
                        <Texting
                          type="p3small-2 blackColor"
                          v-html="postTitle.fields['descriptionheader' + n]"
                        ></Texting>
                      </v-col>
                      <div style="width: 100%"></div>
                      <v-col
                        v-if="postTitle.fields['descriptionimage' + (n + i)]"
                        cols="auto"
                        class="align-start d-flex pt-7"
                      >
                        <Images
                          v-if="postTitle.fields['descriptionimage' + (n + i)]"
                          :name="
                            postTitle.fields['descriptionimage' + (n + i)].url
                          "
                          width="100%"
                          style="margin-right: 18px"
                          class="d-flex"
                        />
                      </v-col>
                      <v-col
                        v-if="postTitle.fields['descriptionimage' + 2 * n]"
                        cols="auto"
                        class="align-start d-flex pl-3 pt-7"
                      >
                        <Images
                          v-if="postTitle.fields['descriptionimage' + 2 * n]"
                          :name="
                            postTitle.fields['descriptionimage' + 2 * n].url
                          "
                          width="100%"
                          style=""
                          class="d-flex"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <hr
                        style="
                          width: 100%;
                          border-top: 1px solid #1e4a6e;
                          margin-top: 0;
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row class="d-block d-sm-none">
                    <v-col cols="auto" class="d-flex pb-8 pt-0">
                      <Images
                        @click.native="shareFB"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/fb_blog_icon_kfaln7.svg"
                        style="margin-top: 60px; margin-bottom: 16px"
                        class="pointer d-block d-sm-flex blogpost-fb-icon"
                      />
                      <Images
                        @click.native="shareTwitter"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/twitter_blog_icon_gl0qgc.svg"
                        style="margin-bottom: 16px"
                        class="
                          pointer
                          d-block d-sm-flex
                          mb-0 mb-sm-4
                          mx-4 mx-sm-0
                        "
                      />
                      <Images
                        @click.native="shareIn"
                        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1618813913/images/linkedin_blog_icon_srnr7c.svg"
                        class="pointer d-block d-sm-flex"
                      />
                    </v-col>
                  </v-row>
                  <v-row id="post-comment-row">
                    <v-col cols="auto" style="padding-top: 3px">
                      <Texting type="p4-1 MainDark">Leave a Reply</Texting>
                    </v-col>
                    <v-col cols="12" style="padding-top: 5px">
                      <v-text-field
                        :id="'comment-author-name-' + postId"
                        background-color="#FFFFFF"
                        v-model="form.name"
                        :class="{
                          'error--text v-input--has-state':
                            $v.form.name.$dirty && !$v.form.name.required,
                        }"
                        name="name"
                        hide-details
                        placeholder="Name*"
                        outlined
                      >
                      </v-text-field>
                      <div class="mylabel">
                        <Texting
                          type="p1 Fivethly"
                          v-if="$v.form.name.$dirty && !$v.form.name.required"
                          >Name is required</Texting
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" style="padding-top: 0px">
                      <v-text-field
                        :id="'comment-author-email-' + postId"
                        v-model.trim="form.email"
                        background-color="#FFFFFF"
                        :class="{
                          'error--text v-input--has-state':
                            ($v.form.email.$dirty && !$v.form.email.required) ||
                            ($v.form.email.$dirty && !$v.form.email.email),
                        }"
                        hide-details
                        name="email"
                        outlined
                        placeholder="Email*"
                      >
                      </v-text-field>
                      <div class="mylabel">
                        <Texting
                          type="p1 Fivethly"
                          v-if="$v.form.email.$dirty && !$v.form.email.required"
                          >Email is required</Texting
                        >
                        <Texting
                          type="p1 Fivethly"
                          v-if="$v.form.email.$dirty && !$v.form.email.email"
                          >Email address is not valid</Texting
                        >
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="blog-comment-textarea-column"
                      style="padding-top: 3px"
                    >
                      <v-textarea
                        :id="'comment-text-' + postId"
                        v-model="form.comment"
                        background-color="#FFFFFF"
                        :class="{
                          'error--text v-input--has-state':
                            $v.form.comment.$dirty && !$v.form.comment.required,
                        }"
                        name="comment"
                        outlined
                        hide-details
                        height="113px"
                        no-resize
                        placeholder="Leave a Comment*"
                      >
                      </v-textarea>
                      <div class="mylabel">
                        <Texting
                          type="p1 Fivethly"
                          v-if="
                            $v.form.comment.$dirty && !$v.form.comment.required
                          "
                          >Comment is required</Texting
                        >
                      </div>
                    </v-col>
                    <v-col cols="auto" style="padding-top: 0" class="ml-auto">
                      <Button
                        :id="'post-button-' + postId"
                        type="primary"
                        size="normal"
                        texting="p2b mywhite"
                        :handleClick="createComment"
                        >Post</Button
                      >
                    </v-col>
                  </v-row>
                  <v-row class="px-3 pt-5">
                    <v-col
                      cols="12"
                      v-for="(comment, i) in comments"
                      :key="i"
                      style="
                        border: 1px solid #eadcec;
                        box-sizing: border-box;
                        border-radius: 12px;
                        margin-top: 20px;
                        background-color: #ffffff;
                      "
                    >
                      <Texting
                        type="p2b MainDark"
                        style="word-wrap: break-word"
                        >{{ comment.name }}</Texting
                      >
                      <Texting
                        type="p1 asphalt"
                        style="margin-top: -5px !important"
                        >{{ getDateText(comment.createdAt) }}</Texting
                      >
                      <Texting
                        type="p2 blackColor"
                        style="
                          margin-top: 8px !important;
                          word-wrap: break-word;
                        "
                        >{{ comment.comment }}</Texting
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <AppFooter />
  </div>
</template>

<script>
//import Images from "@/components/Images/Images";
import api from "@/services/agility/agility.client";
import Images from "@/components/MyImages";
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
//import Button from "@/components/Button/Button";
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import Button from "@/components/Button";
import { mapActions } from "vuex";
import axios from "axios";
import myServices from "../services/resource";
import { required, email } from "vuelidate/lib/validators";

let contentAgility;
let postId;

export default {
  components: {
    Button,
    AppHeader,
    AppFooter,
    Texting,
    Heading,
    Images,
  },
  data: () => ({
    comments: [],
    content: {},
    subTitles: [],
    postId: null,
    form: {
      name: null,
      email: null,
      comment: null,
    },
    // comments: [
    //     {
    //         name: "Theresa Webb",
    //         date: "Feb 12, 2021",
    //         content:
    //             "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    //     },
    //     {
    //         name: "Theresa Webb",
    //         date: "Feb 12, 2021",
    //         content:
    //             "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    //     },
    //     {
    //         name: "Theresa Webb",
    //         date: "Feb 12, 2021",
    //         content:
    //             "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    //     },
    // ],
  }),
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      comment: {
        required,
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    api
      .getContentList({
        referenceName: "BlogPosts",
        languageCode: "en-us",
      })
      .then(function (contentIList) {
        let contentListResult = contentIList;
        let posts = contentListResult.items;
        let urlKeywords = to.params.urlkeywords;
        const currentPost = posts.find(
          (element) => element.fields.urlkeywords === urlKeywords,
        );
        postId = currentPost.fields.postContent_ValueField;
        api
          .getContentItem({
            contentID: postId,
            languageCode: "en-us",
          })
          .then(function (contentItem) {
            contentAgility = contentItem;
            next((vm) => {
              vm.afterFetch();
            });
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          });
        // contentAgility = await api.getContentItem({
        //   contentID: postId,
        //   languageCode: "en-us"
        // });
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  },
  beforeDestroy: function () {
    let list = document.getElementsByTagName("META");
    for (const item of list) {
      if (item.property) {
        if (
          item.property.includes("title") ||
          item.property.includes("url") ||
          item.property.includes("image") ||
          item.property.includes("description")
        ) {
          item.content = "";
        }
      }
    }
  },
  // metaInfo() {
  //   if(contentAgility) {
  //     return {
  //         title: `${contentAgility.fields.header}`,
  //         meta: [{
  //                 name: 'og:description',
  //                 content: `${contentAgility.seo.metaDescription}`
  //             },
  //             {
  //                 property: 'og:title',
  //                 content: `${contentAgility.fields.header}`
  //             },
  //             {
  //                 property: 'og:image',
  //                 content: `${contentAgility.fields.headerImage.url}`
  //             },
  //         ]
  //     }
  //   }
  // },
  watch: {
    "$store.state.comments": function () {
      this.comments = this.$store.state.comments;
    },
  },
  methods: {
    ...mapActions(["getAllComments"]),

    createComment() {
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        return;
      }

      this.form.postId = this.postId;

      axios
        .post(`/api/blog/createComment`, this.form)
        .then(() => {
          this.getAllComments(this.postId);
          this.form.name = "";
          this.form.email = "";
          this.form.comment = "";
          this.$v.form.$reset();
        })
        .catch((error) => {
          myServices.showErrorMessage(this, error);
        });
    },
    afterFetch() {
      this.postId = postId;
      let contentResult = contentAgility;
      this.content = contentResult.fields;
      this.subTitles = this.content.postTitles;
      this.getAllComments(this.postId);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let event = new Event("renderer-ready");
      window.document.dispatchEvent(event);
      this.$meta().setOptions({
        title: `${contentAgility.fields.header}`,
        meta: [
          {
            name: "og:description",
            content: `${contentAgility.seo.metaDescription}`,
          },
          {
            property: "og:title",
            content: `${contentAgility.fields.header}`,
          },
          {
            property: "og:image",
            content: `${contentAgility.fields.headerImage.url}`,
          },
        ],
      });
      this.$meta().refresh();
    },
    onListItemClick(event) {
      // let element = event.target;
      // if(element.tagName === 'SPAN') {
      //   element = element.parentNode;
      // }
      let element;
      let header = event.target.innerHTML;
      let lis = document
        .getElementById("blog-post-list")
        .getElementsByTagName("li");
      let child;
      for (let i = 0; i < lis.length; i++) {
        if (lis[i].innerHTML.includes(header) || lis[i].innerHTML === header) {
          child = i;
          break;
        }
      }
      for (const postTitle of this.subTitles) {
        element = document.getElementById(
          `postTitle-${postTitle.contentID + "-" + child}`,
        );
        if (typeof element !== "undefined" && element !== null) {
          this.$scrollTo(element, 500, { offset: -60 });
          break;
        }
      }
    },
    shareFB() {
      myServices.myAnalyticsEvents(
        this,
        "Blog",
        "Blog-social Facebook- " + this.content.header,
      );
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href,
        )}`,
        "_blank",
        "location=yes,height=570,width=820,scrollbars=yes,status=yes",
      );
    },
    shareTwitter() {
      myServices.myAnalyticsEvents(
        this,
        "Blog",
        "Blog-social Twitter- " + this.content.header,
      );
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          window.location.href,
        )}`,
        "_blank",
        "location=yes,height=570,width=820,scrollbars=yes,status=yes",
      );
    },
    shareIn() {
      myServices.myAnalyticsEvents(
        this,
        "Blog",
        "Blog-social Linkedin- " + this.content.header,
      );
      //window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`, '_blank', 'location=yes,height=570,width=820,scrollbars=yes,status=yes');
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          window.location.href,
        )}`,
        "_blank",
        "location=yes,height=570,width=820,scrollbars=yes,status=yes",
      );
    },
    getDateText(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = monthNames[new Date(date).getMonth()];
      let day = new Date(date).getDate();
      let year = new Date(date).getFullYear();
      return month + " " + day + ", " + year;
    },
  },
};
</script>

<style scoped>
.blog-header {
  text-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}

.circle-icon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #8e9ca3;
}

@media screen and (max-width: 599px) {
  .blogpost-fb-icon {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .blog-post-header {
    padding-top: 10px !important;
  }
}

a {
  color: #9e33e0;
  text-decoration: none;
}
</style>
